<template>
  <div class="container-fluid mt-2 mb-5">
    <vs-dialog blur prevent-close v-model="openSoil">
      <Soil :itemData="selectedFarm" />
    </vs-dialog>
    <vs-dialog blur prevent-close v-model="openDavis">
      <Davis :itemData="selectedFarm" />
    </vs-dialog>
    <vs-dialog blur prevent-close v-model="openCampbell">
      <Campbell :itemData="selectedFarm" />
    </vs-dialog>
    <vs-dialog blur prevent-close v-model="openInternet">
      <Internet :itemData="selectedFarm" />
    </vs-dialog>
    <vs-dialog blur prevent-close v-model="openManual">
      <Manual :itemData="selectedFarm" />
    </vs-dialog>
    <vs-dialog blur prevent-close v-model="openAddonConnection">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h3>Addon connection</h3>
            <p>
              You are about to link
              <strong>{{ addonToConnect.name }}</strong> to a farm, remember
              that once the movement is made you will not be able to reverse the
              changes.
            </p>
            <div class="form-group">
              <span>Select your farm</span>
              <select class="form-control" v-model="farmAddon">
                <option value="x">--Select a value--</option>
                <option v-for="item in farms" :key="item.id" v-if="calculateTierDaysRemaining(item.endDate) > 0"
                  :value="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group mt-3">
              <vs-button @click="saveAddonConnection()" block>
                Save Changes
              </vs-button>
            </div>
          </div>
        </div>
      </div>
    </vs-dialog>

    <vs-dialog blur prevent-close v-model="showTiers">
      <template #header>
        <h4 class="not-margin mt-3">
          <div class="d-flex justify-content-center">
            <div class="navbar-brand">
              <img src="@/assets/logo-nav.png" width="133" />
            </div>
          </div>
        </h4>
      </template>
      <Tiers :itemData="selectedFarm" />
    </vs-dialog>

    <vs-dialog blur prevent-close v-model="showAddons">
      <template #header>
        <h4 class="not-margin mt-3">
          <div class="d-flex justify-content-center">
            <div class="navbar-brand">
              <img src="@/assets/logo-nav.png" width="133" />
            </div>
          </div>
        </h4>
      </template>
      <Addons :itemData="selectedFarm" />
    </vs-dialog>

    <vs-dialog blur prevent-close v-model="showAddonRenew">
      <template #header>
        <h4 class="not-margin mt-3">
          <div class="d-flex justify-content-center">
            <div class="navbar-brand">
              <img src="@/assets/logo-nav.png" width="133" />
            </div>
          </div>
        </h4>
      </template>
      <AddonRenew :itemData="selectedAddon" @renew-addon-done="handleAddonRenewal" />
    </vs-dialog>

    <vs-dialog blur prevent-close v-model="showTiersRenew">
      <template #header>
        <h4 class="not-margin mt-3">
          <div class="d-flex justify-content-center">
            <div class="navbar-brand">
              <img src="@/assets/logo-nav.png" width="133" />
            </div>
          </div>
        </h4>
      </template>
      <TiersRenew :itemData="selectedFarmRenew" @renew-done="handleRenewal" />
    </vs-dialog>

    <vs-dialog blur prevent-close v-model="showBackup" v-if="showBackup">
      <div class="container mt-3 mb-3">
        <div class="row">
          <div class="col-md-12">
            <h3>Backup generator</h3>
            <div class="card main-card">
              <div class="card-body">
                <p>
                  According with <strong>{{ selectedFarm.tName }}</strong> you
                  are able to generate a {{ selectedFarm.history }} days ago
                  from today.
                </p>
                <ul class="list-group smallText mb-3">
                  <li class="list-group-item">
                    <strong>From: </strong>
                    {{ moment().subtract(7, "days").format("ll") }}
                  </li>
                  <li class="list-group-item">
                    <strong>To:</strong> {{ moment().format("ll") }}
                  </li>
                </ul>
                <div class="form-group">
                  <p>Select your item</p>
                  <select class="form-control" v-model="selectedBackup">
                    <option v-for="(value, key) in selectedFarm" :key="key" v-if="['scouts'].includes(key) && value > 0"
                      :value="key">
                      {{ key }}
                    </option>
                    <option v-for="(value, key) in selectedFarm" :key="key" v-if="['davis'].includes(key) && value > 0"
                      :value="key">
                      {{ key }}
                    </option>
                    <option v-for="(value, key) in selectedFarm" :key="key" v-if="['campbell'].includes(key)"
                      :value="key">
                      {{ key }}
                    </option>
                    <option v-for="(value, key) in selectedFarm" :key="key" v-if="['internet'].includes(key)"
                      :value="key">
                      {{ key }}
                    </option>
                    <option v-for="(value, key) in selectedFarm" :key="key" v-if="['manual'].includes(key)"
                      :value="key">
                      {{ key }}
                    </option>
                    <!-- <option v-for="(value, key) in selectedFarm" :key="key"
                      v-if="['davis', 'campbell', 'internet', 'manual', 'scouts'].includes(key) && value > 0"
                      :value="key">
                      {{ key }}
                    </option> -->
                  </select>
                </div>
                <div class="form-group mt-3">
                  <vs-button block @click="downloadBackup()">Donwload Backup</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-dialog>

    <div class="row justify-content-start align-items-center">
      <div class="col-md-12">
        <h4 class="d-flex align-items-center">
          <span class="material-icons text-main-gradient me-2">menu</span>
          <strong>My Tiers</strong>
        </h4>
        <p>To display information please click hover a farm/domain.</p>
      </div>

      <div class="col-md-12 mt-2 mb-5">
        <div class="row">
          <div class="col-md-4 mt-3" v-for="(item, index) in farms" :key="index"
            :class="{ expired: calculateTierDaysRemaining(item.endDate) <= 0 }">
            <div class="card main-card2 h-100" :class="{ selectCard: index === selectedCardIndex }"
              @click="handleClickFarm(item, index)">
              <div class="card-header">
                <h5 class="text-main-gradient">{{ item.name }}</h5>
              </div>
              <div class="card-body">
                <p>
                  {{ item.tName }} <br />
                  <strong>Mode: </strong>{{ item.tierMode }} <br />
                  <strong>Start Date: </strong>{{ moment.utc(item.startDate).format("ll") }} <br />
                  <strong>End Date: </strong>{{ moment.utc(item.endDate).format("ll") }} <br />
                  <template v-if="calculateTierDaysRemaining(item.endDate) > 30">
                    <span class="badge rounded-pill bg-success text-white smallText">Days Remaining:
                      {{ calculateTierDaysRemaining(item.endDate) }}</span>
                  </template>
                  <template v-if="
                      calculateTierDaysRemaining(item.endDate) <= 30 &&
                      calculateTierDaysRemaining(item.endDate) > 15
                    ">
                    <span class="badge rounded-pill bg-warning text-white smallText float">Days Remaining:
                      {{ calculateTierDaysRemaining(item.endDate) }}</span>
                    <span class="text-muted smallText">
                      <span class="material-icons smallText ms-3">refresh</span>
                      <span class="text-link smallText me-2" @click="enableRenew()">Renew now</span>
                    </span>
                  </template>
                  <template v-if="
                      calculateTierDaysRemaining(item.endDate) <= 15 &&
                      calculateTierDaysRemaining(item.endDate) > 0
                    ">
                    <span class="badge rounded-pill text-white bg-danger smallText float">Days Remaining:
                      {{ calculateTierDaysRemaining(item.endDate) }}</span>
                    <span class="text-muted smallText">
                      <span class="material-icons smallText ms-3">refresh</span>
                      <span class="text-link smallText me-2" @click="enableRenew()">Renew now</span>
                    </span>
                  </template>

                  <template v-if="calculateTierDaysRemaining(item.endDate) <= 0">
                    <span class="badge rounded-pill text-white bg-danger smallText float">Expired</span>
                    <span class="text-muted smallText renew-button">
                      <!-- <span class="material-icons smallText ms-3 renew-button">refresh</span>
                      <span class="text-link smallText me-2 renew-button" @click="enableRenew()">Renew now</span> -->
                    </span>
                  </template>
                </p>
                <span class="text-muted smallText"><span class="material-icons smallText me-1">timer</span>
                  Sample time {{ item.sampleTime }} minutes</span>
                <br />
                <span class="text-muted smallText"><span class="material-icons smallText me-1">cloud_download</span>
                  {{ item.history }} backup data</span>
                <br />
                <span class="text-muted smallText"><span class="material-icons smallText me-1">folder</span>{{
                  item.storage_path }}</span>
                <br />

                <span class="text-muted smallText" v-if="hasPermission('receipts', user.permissions)" :class="{
                    cancelClick: calculateTierDaysRemaining(item.endDate) <= 0,
                  }">
                  <span class="material-icons smallText me-1">receipt</span>
                  <a :href="item.receiptUrl" target="_blank" class="text-link smallText me-2">Receipt Url</a>
                </span>
                <span class="text-muted smallText" v-if="hasPermission('backups', user.permissions)" :class="{
                    cancelClick: calculateTierDaysRemaining(item.endDate) <= 0,
                  }">
                  <span class="material-icons smallText me-1">history</span>
                  <a @click="displayBackup(item)" class="text-link smallText me-2">Donwload Backup</a>
                </span>
                <!-- <span class="text-muted smallText" v-if="hasPermission('storage',user.permissions)"
                  :class="{'cancelClick': calculateTierDaysRemaining(item.endDate) <=0}"><span
                    class="material-icons smallText me-1">folder</span>
                  <a href="#" @click="handleFileUpload(item)" class="text-link smallText me-2">Change strage path</a>
                </span> -->
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3" v-if="user.addons">
          <div class="col-md-12">
            <h4 class="d-flex align-items-center click" @click="showMyAddons()">
              <span class="material-icons text-main-gradient me-2">electrical_services</span>
              <strong>My Addons</strong>
              <span class="material-icons text-main-gradient me-2" v-if="displayMainAddons">arrow_drop_up</span>
              <span class="material-icons text-main-gradient me-2" v-else>arrow_drop_down</span>
            </h4>
          </div>
          <div class="col-md-4" v-if="displayMainAddons" v-for="item in user.addons" :key="item.id">
            <div class="card main-card2 mt-3">
              <div class="card-body">
                <span class="smallText">
                  <template v-if="item.dbName == '10StocusAddon'">
                    <img src="@/assets/scouts.png" width="18" />
                  </template>
                  <template v-if="item.dbName == '10DavisAddon'">
                    <img src="@/assets/davis.png" width="18" />
                  </template>
                  <template v-if="item.dbName == '1MonthAddon'">
                    <img src="@/assets/cloud.png" width="18" />
                  </template>
                  <template v-if="item.dbName == '3MonthAddon'">
                    <img src="@/assets/cloud.png" width="18" />
                  </template>
                  <template v-if="item.dbName == 'AnnualHostingAddon'">
                    <img src="@/assets/hosting.png" width="18" />
                  </template>

                  <strong class="ms-2 mr-3">{{ item.name }}</strong>
                  <span v-if="parseInt(item.hasFarm) > 0">Attached to Farm</span><br />
                  <span class="text-muted smallText mr-3">Start Date:
                    {{ moment.utc(item.startDate).format("ll") }}</span>
                  <br />

                  <template v-if="item.dbName != 'AnnualHostingAddon'">
                    <template v-if="parseInt(item.hasFarm) == 0">
                      <span class="text-muted smallText" v-if="hasPermission('receipts', user.permissions)" :class="{
                          cancelClick:
                            calculateTierDaysRemaining(item.endDate) <= 0,
                        }">
                        <span class="material-icons smallText me-1">cable</span>
                        <a class="text-link smallText me-2" @click="openAddon(item)">Connect to Farm</a>
                      </span>
                    </template>
                  </template>
                </span>
              </div>
            </div>
          </div>
        </div>

        <transition name="fade">
          <div class="row mt-5" v-if="selectedFarm">
            <div class="col-md-12">
              <h3 class="text-main-gradient-secondary">
                Available features for: {{ selectedFarm.name }}
              </h3>
              <p>Select a feature to start or see configurations.</p>
            </div>
            <div class="col-md-3 mt-3" v-for="(value, index) in selectedFarm" :key="index" v-if="index === 'davis'">
              <div class="card main-card2" :class="{ selectCard: index === selectedCardIndex2 }">
                <div class="card-body text-center">
                  <img src="@/assets/davis.png" width="33" />
                  <h5>Davis W.S</h5>
                  <p> Available stations: {{ value }} <br>
                    Available nodes: {{ selectedFarm.davis_nodes }}</p>
                  <!-- <p>ID de la estación: {{ selectedFarm.stations.find(station => station.sensor_type === 1).id }}</p> -->
                  <template v-if="parseInt(value) == 2">
                    <a class="text-link smallText ms-2" @click="openDavis = true"
                      v-if="hasPermission('station', user.permissions)">Add station</a>
                    <a class="ms-3 text-link smallText" @click="displayDavisData(selectedFarm, index)">Display
                      Records</a>
                  </template>
                  <template v-else>
                    <template v-if="parseInt(selectedFarm.realTierId) == 1">
                      <template v-if="parseInt(value) == 1">
                        <a class="text-link smallText ms-2" @click="openDavis = true"
                          v-if="hasPermission('station', user.permissions)">Add station</a>
                        <a class="ms-3 text-link smallText" @click="displayDavisData(selectedFarm, index)">Display
                          Records</a>
                      </template>
                      <template v-else>
                        <template v-if="parseInt(value) === 0">
                          <!-- <a class="text-link smallText" @click="openDavis=true">Add station</a> -->
                          <a class="ms-3 text-link smallText" @click="displayDavisData(selectedFarm, index)">Display
                            Records</a>
                        </template>
                        <template v-else>
                          <a class="text-link smallText" @click="openDavis = true">Add station</a>
                          <a class="ms-3 text-link smallText" @click="displayDavisData(selectedFarm, index)">Display
                            Records</a>
                        </template>
                      </template>
                    </template>
                    <template v-else>
                      <template v-if="parseInt(value) > 0">
                        <a class="text-link smallText" @click="openDavis = true"
                          v-if="hasPermission('sensor', user.permissions)">Add station</a>
                        <a class="ms-3 text-link smallText" @click="displayDavisData(selectedFarm, index)">Display
                          Records</a>
                      </template>
                      <template v-if="parseInt(value) === 0">
                        <!-- <a class="text-link smallText" @click="openDavis=true">Add station</a> -->
                        <a class="ms-3 text-link smallText" @click="displayDavisData(selectedFarm, index)">Display
                          Records</a>
                      </template>
                    </template>
                  </template>
                </div>
              </div>
            </div>

            <div class="col-md-3 mt-3" v-for="(value, index) in selectedFarm" :key="index" v-if="index == 'scouts'">
              <div class="card main-card2" :class="{ selectCard: index === selectedCardIndex2 }">
                <div class="card-body text-center">
                  <img src="@/assets/scouts.png" width="33" />
                  <h5>Soil Scouts</h5>
                  <p>Available items: {{ value }}</p>
                  <!-- <p>ID de la estación: {{ selectedFarm.stations.find(station => station.sensor_type === 3).id }}</p> -->

                  <template v-if="parseInt(value) == 10">
                    <a class="text-link smallText ms-2" @click="openSoil = true"
                      v-if="hasPermission('station', user.permissions)">Add sensor</a>
                  </template>
                  <template v-else>
                    <template v-if="parseInt(value) > 0">
                      <a class="text-link smallText" @click="openSoil = true"
                        v-if="hasPermission('sensor', user.permissions)">Add sensor</a>
                      <a class="ms-3 text-link smallText" @click="displaySoilData(selectedFarm, index)">Display
                        Records</a>
                    </template>
                  </template>
                </div>
              </div>
            </div>

            <div class="col-md-3 mt-3" v-for="(value, index) in selectedFarm" :key="index" v-if="index == 'campbell'">
              <div class="card main-card2" :class="{ selectCard: index === selectedCardIndex2 }">
                <div class="card-body text-center">
                  <img src="@/assets/campbell.png" width="33" />
                  <h5>Campbell W.S</h5>
                  <p>Available items: {{ value }}</p>
                  <template v-if="parseInt(value) == 1">
                    <a class="text-link smallText ms-2" @click="openCampbell = true"
                      v-if="hasPermission('station', user.permissions)">Add sensor</a>
                  </template>
                  <template v-else>
                    <template v-if="parseInt(value) > 1">
                      <a class="text-link smallText" @click="openCampbell = true"
                        v-if="hasPermission('sensor', user.permissions)">Add sensor</a>
                      <a class="ms-3 text-link smallText" @click="displayCampbellData(selectedFarm, index)">Display
                        Records</a>
                    </template>
                    <template v-if="parseInt(value) === 0">
                      <!-- <a class="text-link smallText" @click="openDavis=true">Add sensor</a> -->
                      <a class="ms-3 text-link smallText" @click="displayCampbellData(selectedFarm, index)">Display
                        Records</a>
                    </template>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-md-3 mt-3" v-for="(value, index) in selectedFarm" :key="index" v-if="index == 'internet'">
              <div class="card main-card2" :class="{ selectCard: index === selectedCardIndex2 }">
                <div class="card-body text-center">
                  <img src="@/assets/internet.png" width="33" />
                  <h5>Internet Weather</h5>
                  <p>Available items: {{ value }}</p>
                  <template v-if="parseInt(value) == 2">
                    <a class="text-link smallText ms-2" @click="openInternet = true"
                      v-if="hasPermission('station', user.permissions)">Add sensor</a>
                  </template>
                  <template v-else>
                    <template v-if="parseInt(value) <= 2 && parseInt(value) >= 1">
                      <a class="text-link smallText" @click="openInternet = true"
                        v-if="hasPermission('sensor', user.permissions)">Add sensor</a>
                      <a class="ms-3 text-link smallText" @click="displayInternetData(selectedFarm, index)">Display
                        Records</a>
                    </template>
                    <template v-if="parseInt(value) === 0">
                      <!-- <a class="text-link smallText" @click="openDavis=true">Add sensor</a> -->
                      <a class="ms-3 text-link smallText" @click="displayInternetData(selectedFarm, index)">Display
                        Records</a>
                    </template>
                  </template>
                </div>
              </div>
            </div>
            <div class="col-md-3 mt-3" v-for="(value, index) in selectedFarm" :key="index" v-if="index == 'manual'">
              <div class="card main-card2" :class="{ selectCard: index === selectedCardIndex2 }">
                <div class="card-body text-center">
                  <img src="@/assets/manual.png" width="33" />
                  <h5>Manual Records</h5>
                  <p>Available items: ♾️</p>
                  <template v-if="parseInt(value) == 999999">
                    <a class="text-link smallText ms-2" @click="openManual = true"
                      v-if="hasPermission('station', user.permissions)">Add sensor</a>
                  </template>
                  <template v-else>
                    <template v-if="parseInt(value) <= 999998">
                      <a class="text-link smallText" @click="openManual = true"
                        v-if="hasPermission('sensor', user.permissions)">Add sensor</a>
                      <a class="ms-3 text-link smallText" @click="displayManualData(selectedFarm, index)">Display
                        Records</a>
                    </template>
                    <template v-if="parseInt(value) === 0">
                      <!-- <a class="text-link smallText" @click="openDavis=true">Add sensor</a> -->
                      <a class="ms-3 text-link smallText" @click="displayManualData(selectedFarm, index)">Display
                        Records</a>
                    </template>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>

    <div class="row" v-if="showSoilData">
      <SoilData :itemData="selectedFarm" @soil-turned="handleSoilTurned" @soil-deleted="handleSoilDeleted" />
    </div>
    <div class="row" v-if="showDavisData">
      <DavisData :itemData="selectedFarm" @davis-turned="handleDavisTurned" @davis-deleted="handleDavisDeleted" />
    </div>
    <div class="row" v-if="showCampbellData">
      <CampbellData :itemData="selectedFarm" @campbell-turned="handleCampbellTurned"
        @campbell-deleted="handleCampbellDeleted" />
    </div>
    <div class="row" v-if="showInternetData">
      <InternetData :itemData="selectedFarm" @internet-turned="handleInternetTurned"
        @internet-deleted="handleInternetDeleted" />
    </div>

    <div class="row" v-if="showManualData">
      <ManualData :itemData="selectedFarm" @manual-turned="handleManualTurned" @manual-deleted="handleManualDeleted" />
    </div>
    <mainTimer v-if="resetTimer" :itemData="selectedFarm" />
  </div>
</template>
<script>
  import { mapActions } from "vuex";
  import axios from "axios";
  import SetInterval from "set-interval";
  import Tiers from "@/views/Tiers.vue";
  import TiersRenew from "@/views/TiersRenew.vue";
  import Addons from "@/views/Addons.vue";
  import AddonRenew from "@/views/AddonRenew.vue";
  import Soil from "@/views/Soil.vue";
  import Davis from "@/views/Davis.vue";
  import Campbell from "@/views/Campbell.vue";
  import SoilData from "@/views/Soil-Scouts.vue";
  import Internet from "@/views/Internet.vue";
  import Manual from "@/views/Manual.vue";
  import DavisData from "@/views/Davis-data.vue";
  import CampbellData from "@/views/Campbell-data.vue";
  import InternetData from "@/views/Internet-data.vue";
  import ManualData from "@/views/Manual-data.vue";
  import mainTimer from "@/views/mainTimer.vue";
  import moment from "moment"; // Importa Moment.js

  var config = require("@/config/api.json");
  const API = config.api;
  export default {
    name: "Farms",
    components: {
      Soil,
      SoilData,
      Davis,
      DavisData,
      mainTimer,
      Tiers,
      Addons,
      Campbell,
      CampbellData,
      Internet,
      InternetData,
      Manual,
      ManualData,
      TiersRenew,
      AddonRenew,
    },
    watch: {
      "$store.state.addonCalled"(newVal) {
        console.log("escuchado..............", newVal);
        if (newVal) {
          this.displayAddons();
        }
      },
      "$store.state.tierCalled"(newVal) {
        console.log("escuchado..............", newVal);
        if (newVal) {
          this.displayTiers();
        }
      },
      openSoil(value) {
        console.log('seleccionada',this.selectedFarm);
        if (!value) {
          this.handleClickFarmCloseEvent(
            this.selectedFarm,
            this.selectedCardIndex
          );
        }
      },
      openDavis(value) {
        console.log("OPEN DAVIS", this.selectedFarm);
        if (!value) {
          this.handleClickFarmCloseEventDavis(
            this.selectedFarm,
            this.selectedCardIndex
          );
        }
      },
      openCampbell(value) {
        console.log("OPEN CAMPBELL", this.selectedFarm);
        if (!value) {
          this.handleClickFarmCloseEventCampbell(
            this.selectedFarm,
            this.selectedCardIndex
          );
        }
      },
      openInternet(value) {
        console.log("OPEN INTERNET", this.selectedFarm);
        if (!value) {
          this.handleClickFarmCloseEventInternet(
            this.selectedFarm,
            this.selectedCardIndex
          );
        }
      },
      openManual(value) {
        console.log("OPEN INTERNET", this.selectedFarm);
        if (!value) {
          this.handleClickFarmCloseEventManual(
            this.selectedFarm,
            this.selectedCardIndex
          );
        }
      },
    },
    data() {
      return {
        user: null,
        farms: [],
        active: false,
        options: [],
        storedUser: [null],
        selectedFarm: null,
        selectedFarmRenew: null,
        selectedCardIndex: -1,
        selectedCardIndex2: -1,
        openSoil: false,
        openDavis: false,
        openCampbell: false,
        openInternet: false,
        openManual: false,
        openAddonConnection: false,
        showSoilData: false,
        showDavisData: false,
        showCampbellData: false,
        showInternetData: false,
        showManualData: false,
        resetTimer: false,
        storage_pathEdit: null,
        showBackup: false,
        selectedBackup: "scouts",
        showTiers: false,
        showAddons: false,
        showTiersRenew: false,
        showAddonRenew: false,
        displayMainAddons: false,
        selectedAddon: [],
        addonToConnect: [],
        farmAddon: "x",
      };
    },

    mounted() {
      this.openLoading();
      setTimeout(() => {
        this.storedUser = localStorage.getItem("user");
        if (this.storedUser) {
          this.user = JSON.parse(this.storedUser);
          this.getFarms();
          this.resetTimer = true;
        }
        this.closeLoading();
      }, 1000);


      // ipcRenderer.on("getRoute", (event, args) => {
      //   if (!args.route[0]) {
      //     return;
      //   } else {
      //     var regex = /\\/g;
      //     var FilePath = args.route[0].replace(regex, "\\\\");
      //     this.storage_pathEdit = FilePath;
      //     console.log(this.storage_pathEdit, this.selectedFarm);
      //     this.updateStoragePath();
      //   }
      // });
    },
    methods: {
      async saveAddonConnection() {
        console.log(this.farmAddon, this.addonToConnect);

        if (this.farmAddon == "x") {
          this.notify("error", "Please select a farm");
          return;
        }

        this.openLoading();

        const addon = {
          id: this.farmAddon,
          user_addon: this.addonToConnect.id,
          addon: this.addonToConnect.addon,
          dbName: this.addonToConnect.dbName,
        };

        try {
          await axios.post(`${API}/saveAddonConnection`, addon);
        } catch (error) {
          this.closeLoading();
          this.resetTimer = true;
          this.openAddonConnection = false;
          this.notify("error", error.response.data.error);
        } finally {
          this.resetTimer = true;
          this.openAddonConnection = false;
          this.closeLoading();
          this.notify("success", "Addon connected successfully");

          setTimeout(() => {
            location.reload();
          }, 2000);
        }
      },

      openAddon(item) {
        this.openAddonConnection = true;
        this.addonToConnect = item;
      },

      enableRenew() {
        this.showTiersRenew = true;
      },
      enableRenewAddon(item) {
        console.log("RENEW ADDON ITEM", item);
        this.selectedAddon = item;
        this.showAddonRenew = true;
      },
      calculateAddonsDaysRemaining(endDate) {
        const today = moment();
        const end = moment(endDate);
        return end.diff(today, "days");
      },
      calculateTierDaysRemaining(endDate) {
        const today = moment();
        const end = moment(endDate);
        return end.diff(today, "days");
      },
      showMyAddons() {
        this.displayMainAddons = !this.displayMainAddons;
      },
      displayAddons() {
        this.showAddons = true;
      },
      displayTiers() {
        this.showTiers = true;
      },

      downloadBackup() {
        const id = this.selectedFarm.id;
        // Obtener la fecha actual en formato MySQL
        const currentDate = moment().format("YYYY-MM-DD");

        // Restar 7 días a la fecha actual
        const fromDate = moment().subtract(7, "days").format("YYYY-MM-DD");

        // Agregar la hora '00:00:00' a ambas fechas
        const from = `${fromDate} 00:00:00`;
        const to = `${currentDate} 23:59:59`;

        var url;
        switch (this.selectedBackup) {
          case "scouts":
            url = `${API}/backupSoil/${id}/${from}/${to}`;
            break;
          case "davis":
            url = `${API}/backupDavis/${id}/${from}/${to}`;
            break;
          case "campbell":
            url = `${API}/backupCampbell/${id}/${from}/${to}`;
            break;
          case "internet":
            url = `${API}/backupInternet/${id}/${from}/${to}`;
            break;
          case "manual":
            url = `${API}/backupManual/${id}/${from}/${to}`;
            break;
          default:
            break;
        }
        // Crea la URL con los parámetros necesarios

        // Realiza la solicitud Axios para descargar el archivo
        axios
          .get(url, { responseType: "blob" }) // Usamos responseType: 'blob' para indicar que esperamos una respuesta binaria (archivo)
          .then((response) => {
            // Crear un objeto Blob con los datos binarios de la respuesta
            const blob = new Blob([response.data], { type: "application/zip" });

            // Crear un objeto URL para el Blob
            const url = window.URL.createObjectURL(blob);

            // Crear un enlace temporal para descargar el archivo
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = "backup.zip"; // Nombre del archivo ZIP
            document.body.appendChild(a);
            a.click();

            // Liberar el objeto URL y eliminar el enlace temporal
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            this.showBackup = false;
          })
          .catch((error) => {
            console.error("Error al descargar el archivo ZIP:", error);
            // Manejar el error según sea necesario
          });
      },

      displayBackup(item) {
        this.selectedFarm = item;
        2;
        setTimeout(() => {
          this.showBackup = true;
        }, 500);
      },

      async updateStoragePath() {
        this.resetTimer = false;
        if (!this.vEmpty(this.storage_pathEdit, "Storage Path ")) {
          return;
        }
        if (this.storage_pathEdit.length > 255) {
          this.notify("error", "Storage path is too large");
          return;
        }
        const newFarm = {
          id: this.selectedFarm.id,
          storage_path: this.storage_pathEdit,
        };
        this.openLoading();

        try {
          await axios.post(`${API}/editFarmPath`, newFarm);
          await this.updateUserInfo(this.user.parent_uid);
        } catch (error) {
          this.closeLoading();
          this.resetTimer = true;
          this.notify("error", error.response.data.error);
        } finally {
          this.resetTimer = true;
          this.storage_pathEdit = null;
          this.getFarms();
          this.closeLoading();
        }
      },

      handleRenewal() {
        console.log(
          "renew done................",
          this.selectedFarm,
          this.selectedCardIndex
        );
        this.showTiersRenew = false;
        location.reload();
      },

      handleAddonRenewal() {
        this.showAddonRenew = false;
        location.reload();
      },

      handleFileUpload(item) {
        this.selectedFarm = item;
        // ipcRenderer.send("select-dirs", true);
      },
      handleSoilTurned() {
        this.resetTimer = false;
        setTimeout(() => {
          this.resetTimer = true;
        }, 333);
      },
      handleSoilDeleted() {
        console.log(
          "llega soil deleted................",
          this.selectedFarm,
          this.selectedCardIndex
        );
        this.handleClickFarmCloseEvent(this.selectedFarm, this.selectedCardIndex);
      },
      handleDavisDeleted() {
        console.log(
          "llega davis deleted................",
          this.selectedFarm,
          this.selectedCardIndex
        );
        this.handleClickFarmCloseEventDavis(
          this.selectedFarm,
          this.selectedCardIndex
        );
      },
      handleCampbellDeleted() {
        console.log(
          "llega campbell deleted................",
          this.selectedFarm,
          this.selectedCardIndex
        );
        this.handleClickFarmCloseEventCampbell(
          this.selectedFarm,
          this.selectedCardIndex
        );
      },
      handleInternetDeleted() {
        console.log(
          "llega campbell deleted................",
          this.selectedFarm,
          this.selectedCardIndex
        );
        this.handleClickFarmCloseEventInternet(
          this.selectedFarm,
          this.selectedCardIndex
        );
      },
      handleManualDeleted() {
        console.log(
          "llega manual deleted................",
          this.selectedFarm,
          this.selectedCardIndex
        );
        this.handleClickFarmCloseEventManual(
          this.selectedFarm,
          this.selectedCardIndex
        );
      },
      handleDavisTurned() {
        this.resetTimer = false;
        setTimeout(() => {
          this.resetTimer = true;
        }, 333);
      },
      handleCampbellTurned() {
        this.resetTimer = false;
        setTimeout(() => {
          this.resetTimer = true;
        }, 333);
      },
      handleInternetTurned() {
        this.resetTimer = false;
        setTimeout(() => {
          this.resetTimer = true;
        }, 333);
      },
      handleManualTurned() {
        this.resetTimer = false;
        setTimeout(() => {
          this.resetTimer = true;
        }, 333);
      },

      displaySoilData(item, index) {
        this.selectedCardIndex2 = index;
        this.showSoilData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        this.showDavisData = false;
        // this.resetTimer = false;
        setTimeout(() => {
          this.showSoilData = true;
          // this.resetTimer = true;
        }, 333);
        console.log(item);
      },
      displayDavisData(item, index) {
        this.selectedCardIndex2 = index;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        this.showSoilData = false;
        this.resetTimer = false;
        setTimeout(() => {
          this.showDavisData = true;
          this.resetTimer = true;
        }, 333);
        console.log(item);
      },
      displayCampbellData(item, index) {
        this.selectedCardIndex2 = index;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        this.showSoilData = false;
        this.resetTimer = false;
        setTimeout(() => {
          this.showCampbellData = true;
          this.resetTimer = true;
        }, 333);
        console.log(item);
      },

      displayInternetData(item, index) {
        this.selectedCardIndex2 = index;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        this.showSoilData = false;
        this.resetTimer = false;
        setTimeout(() => {
          this.showInternetData = true;
          this.resetTimer = true;
        }, 333);
        console.log(item);
      },

      displayManualData(item, index) {
        this.selectedCardIndex2 = index;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        this.showSoilData = false;
        this.resetTimer = false;
        setTimeout(() => {
          this.showManualData = true;
          this.resetTimer = true;
        }, 333);
        console.log(item);
      },

      scrollToBottom() {
        window.scrollTo({
          top: document.documentElement.scrollHeight,
          behavior: "smooth",
        });
      },

      handleClickFarm(item, index) {
        console.log('Seleccionada....',item )
        let days = this.calculateAddonsDaysRemaining(item.endDate);
        if (parseInt(days) <= 0) {
          if (this.hasPermission("buy", this.user.permissions)) {
            this.selectedFarmRenew = item;
            this.enableRenew();
          } else {
            this.notify("info", "You are not allowed to buy tiers");
            return;
          }
        } else {
          this.selectedFarm = null;
          this.showSoilData = false;
          setTimeout(() => {
            this.selectedCardIndex = index;
            this.selectedFarm = item;
            this.scrollToBottom();
          }, 33);
          setTimeout(() => {
            this.scrollToBottom();
          }, 39);
        }
      },
      handleClickFarmCloseEvent(item, index) {
        console.log(item, index);
        this.getFarms();
        this.selectedFarm = null;
        this.resetTimer = false;
        this.showSoilData = false;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;

        setTimeout(() => {
          this.showSoilData = true;
          this.showDavisData = false;
          this.showCampbellData = false;
          this.showInternetData = false;
          this.showManualData = false;
          this.selectedCardIndex = index;
          this.selectedFarm = this.farms[index];
          this.resetTimer = true;
        }, 333);
      },
      handleClickFarmCloseEventDavis(item, index) {
        this.getFarms();
        this.selectedFarm = null;
        this.resetTimer = false;
        this.showSoilData = false;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        setTimeout(() => {
          this.showDavisData = true;
          this.selectedCardIndex = index;
          this.selectedFarm = this.farms[index];
          this.resetTimer = true;
        }, 333);
      },
      handleClickFarmCloseEventCampbell(item, index) {
        this.getFarms();
        this.selectedFarm = null;
        this.resetTimer = false;
        this.showSoilData = false;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        setTimeout(() => {
          this.showCampbellData = true;
          this.selectedCardIndex = index;
          this.selectedFarm = this.farms[index];
          this.resetTimer = true;
        }, 333);
      },
      handleClickFarmCloseEventInternet(item, index) {
        this.getFarms();
        this.selectedFarm = null;
        this.resetTimer = false;
        this.showSoilData = false;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        setTimeout(() => {
          this.showInternetData = true;
          this.selectedCardIndex = index;
          this.selectedFarm = this.farms[index];
          this.resetTimer = true;
        }, 333);
      },
      handleClickFarmCloseEventManual(item, index) {
        this.getFarms();
        this.selectedFarm = null;
        this.resetTimer = false;
        this.showSoilData = false;
        this.showDavisData = false;
        this.showCampbellData = false;
        this.showInternetData = false;
        this.showManualData = false;
        setTimeout(() => {
          this.showManualData = true;
          this.selectedCardIndex = index;
          this.selectedFarm = this.farms[index];
          this.resetTimer = true;
        }, 333);
      },

      async getWeatherDataSoil(serial, path, id, name) {
        await axios
          .get(`${API}/getScoutData/${serial}/${id}`)
          .then((data) => {
            if (data.data == "token_not_valid") {
              this.notify("success", `Scout data taking longer than expected`);
            } else {
              let soilData = data.data[0].last_measurement;

              let us = this.user;
              // ipcRenderer.send("createSoilFile", {
              //   soilData,
              //   us,
              //   path,
              //   id,
              //   serial,
              //   name,
              // });
            }
          })
          .catch((err) => {
            console.log(err);
            this.notify("success", `Error reading Soil: no data received`);
          });
      },

      async turnSoil(id, value, name, serial, path) {
        console.log("lega");
        try {
          const response = await axios.post(`${API}/turnSoil`, {
            id: id,
          });

          console.log(id, value, name, serial, path);

          if (parseInt(value) == 0) {
            SetInterval.clear(`soilScout${id}`);
            this.notify("success", `Stopped ${name}`);
          } else {
            SetInterval.clear(`soilScout${id}`);
            this.getWeatherDataSoil(serial, path, id, name);
            SetInterval.start(
              () => {
                this.getWeatherDataSoil(serial, path, id, name);
              },
              60000,
              `soilScout${id}`
            );
            this.notify("success", `Started ${name}`);
          }
          this.getFarms();
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "Failed to fetch farms");
        }
      },

      async getFarms() {
        this.farms = [];
        console.log(`${API}/getFarmsByOwner/${this.user.id}`);
        try {
          const response = await axios.get(
            `${API}/getFarmsByOwner/${this.user.id}`
          );
          this.farms = response.data;
          console.log("FARMS");
          console.log(response.data);
        } catch (error) {
          console.error("Error", error);
          this.notify("error", "Failed to fetch farms");
        }
      },
      async verifySoil() {
        if (this.farms && this.farms.length > 0) {
          this.farms.forEach((farm) => {
            if (farm.stations && farm.stations.length > 0) {
              const soilStations = farm.stations.filter(
                (station) => station.sensor_type === 3
              );
              if (soilStations.length > 0) {
                soilStations.forEach((station) => {
                  if (station.sensors && station.sensors.length > 0) {
                    const activeSensors = station.sensors.filter(
                      (sensor) => sensor.last_status === 1
                    );
                    if (activeSensors.length > 0) {
                      activeSensors.forEach((element) => {
                        SetInterval.start(
                          () => {
                            this.getWeatherDataSoil(
                              element.config,
                              farm.storage_path,
                              element.id,
                              station.name
                            );
                          },
                          60000,
                          `soilScout${element.id}`
                        );
                        this.getWeatherDataSoil(
                          element.config,
                          farm.storage_path,
                          element.id,
                          station.name
                        );
                      });
                    }
                  }
                });
              }
            }
          });
        }
      },
    },
  };
</script>