import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import router from './router';
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import store from './store'; // Importa tu archivo store.js
import moment from 'moment';


Vue.prototype.moment = moment;


import './mixins/valitators';
Vue.use(Vuesax, {})
Vue.use(VueRouter);

Vue.config.productionTip = false;


let socket;
let isConnected = false;
let isReconnecting = false;  // Flag para evitar múltiples reconexiones
let reconnectTimeout;  // Variable para manejar el reconectado

// Función para conectar WebSocket
function connectWebSocket() {
  console.log('socket', socket)
  // Verificar que no haya una conexión cerrada o en proceso de cierre
  if (socket && socket.readyState !== WebSocket.CLOSED && socket.readyState !== WebSocket.CLOSING) {
    console.log("WebSocket ya está conectado o en proceso de conexión...");
    return;  // No intentamos reconectar si ya está abierto o en conexión
  }

  console.log("🔗 Conectando a WebSocket...");
  socket = new WebSocket("wss://agtl.ink");

  socket.onopen = () => {
    console.log("🔗 WebSocket conectado");
    console.log('socket', socket)
    isConnected = true;
    isReconnecting = false; // Reseteamos el flag de reconexión
    clearTimeout(reconnectTimeout);  // Limpiamos el timeout de reconexión
  };

  socket.onclose = () => {
    console.log("⚠ WebSocket desconectado.");
    console.log('socket', socket)
    isConnected = false;
    if (!isReconnecting) {
      console.log("Intentando reconectar...");
      console.log('socket', socket)
      isReconnecting = true;  // Activamos el flag de reconexión
      reconnectTimeout = setTimeout(connectWebSocket, 5000);  // Intentar reconectar después de 5 segundos
    }
  };

  // socket.onmessage = (event) => {
  //   const message = JSON.parse(event.data);
  //   console.log("📩 Mensaje recibido:", message);
  //   if (message.action === "refresh") {
  //     location.reload(); // Recargar la página si se recibe el mensaje de "refresh"
  //   }
  // };

  socket.onerror = (error) => {
    console.error("Error en WebSocket:", error);
    console.log('socket', socket)
    socket.close();  // Aseguramos que se cierre si ocurre un error
  };

  // Hacer que el WebSocket esté disponible globalmente en Vue.js
  Vue.prototype.$socket = socket;
  Vue.prototype.$isConnected = isConnected;
}

// Inicializar WebSocket al cargar la página
connectWebSocket();





import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyB7dZRt25HvfRTlK9rehEArICUKvRGGezg',
    v: '3.26',
    libraries: 'places,drawing', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
  installComponents: true
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');