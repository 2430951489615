<template>
  <div class="container-fluid mt-2 mb-5">

    <!-- Modal eliminar usuario de fb y de db -->
    <vs-dialog not-center v-model="modalDelete">
      <template #header>
        <h4 class="not-margin">
          Delete <b>paymment method</b>
        </h4>
      </template>
      <div class="con-content">
        <p>
          ¿Are you sure?
        </p>
      </div>
      <template #footer>
        <div class="container">
          <div class="row">
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="deleteMethod()" transparent>
                Yes!
              </vs-button>
            </div>
            <div class="col-6 d-flex align-items-center justify-content-center">
              <vs-button @click="modalDelete=false" danger transparent>
                Cancel
              </vs-button>
            </div>
          </div>
        </div>
      </template>
    </vs-dialog>

    <div class="row justify-content-start align-items-center">
      <div class="col-md-12 ">
        <h3>Settings</h3>
        <button @click="enviarMensajeElectron">Send refresh</button>

      </div>
      <div class="col-md-12" v-if="!isVerified">
        <vs-alert>
          <h3>
            Please verify your account
          </h3>
          <p>
            Please review your inbox mail, we sent you an email to
            verify your agtl.ink account. If you have not received any email, click on the button
            below to send it again. We'll send a verification email again.
          </p>
          <!-- <button type="submit" class="btn btn-primary  mt-2" @click="sendVerify()"><span class="spanButton">Send
              Verification</span></button> -->
          <vs-button block @click="sendVerify()">Send verification</vs-button>
        </vs-alert>
      </div>
      <div class="col-md-12 mt-2 mb-5">

        <div class="card main-card">
          <div class="card-body">
            <h4>My Profile</h4>
            <div class="form-group">
              <p class="smallText">In order to change email or password you must enter your current password.
              </p>
              <input type="password" class="form-control mt-2" placeholder="Current Password"
                v-model.trim="currentPassword" />
              <hr />
              <p class="text-muted">Change Email</p>
              <input type="text" class="form-control mr-1" placeholder="Enter the new email" v-model.trim="userEmail" />

              <!-- <button type="submit" class="btn btn-primary  mt-2" @click="changeEmail()"><span
                  class="spanButton">Save</span></button> -->
              <vs-button @click="changeEmail()">Save</vs-button>
            </div>

            <div class="form-group mt-3">
              <p class="text-muted">Change Password</p>
              <input type="password" class="form-control mt-2" placeholder="New Password" v-model.trim="newPs" />
              <input type="password" class="form-control mt-2" placeholder="Confirm New Password"
                v-model.trim="confirmPs" />
              <!-- <button type="submit" class="btn btn-primary  mt-2" @click="changePass()"><span
                  class="spanButton">Save</span></button> -->
              <vs-button @click="changePass()">Save</vs-button>
            </div>

            <div class="form-group mt-3">
              <p class="text-muted">Change Display Name</p>
              <input type="text" class="form-control mt-2" placeholder="New Display Name"
                v-model.trim="newDisplayName" />
              <!-- <button type="submit" class="btn btn-primary  mt-2" @click="changeDisplayName()"><span
                  class="spanButton">Save</span></button> -->
              <vs-button @click="changeDisplayName()">Save</vs-button>
            </div>
          </div>
        </div>
        <div class="card main-card mt-5 mb-5" v-if="displayBilling">
          <div class="card-body">
            <h4>Billing settings</h4>
            <p>Save your card</p>
            <div ref="cardElement"></div>
            <div class="form-group mt-3">
              <p class="text-muted">Billing owner</p>
              <input type="text" class="form-control mr-1 mt-2" placeholder="Billing Name" v-model.trim="owner" />
              <input type="text" class="form-control mr-1 mt-2" placeholder="TIN/VAT number" v-model.trim="tin"
                maxlength="30" />
              <p class="text-muted mt-3">Billing email</p>
              <input type="text" class="form-control mr-1 mt-2" placeholder="example@agtl.ink" v-model="billingEmail" />
              <p class="text-muted mt-3">Address</p>
              <input type="text" class="form-control mr-1 mt-2" placeholder="Address line 1" v-model="line1" />
              <input type="text" class="form-control mr-1 mt-2" placeholder="Address line 2" v-model="line2" />

              <select class="form-control mr-1 mt-2" v-model="location" name="location" id="location"
                @change="clearFields()">
                <option value="xx" selected>Select your country</option>
                <option v-for="(item, index) in countries" :key="index" :value="item.iso2">{{item.name}}
                </option>
              </select>

              <input type="text" class="form-control mr-1 mt-2" placeholder="Postal code" v-model="postalCode"
                @keyup="asyncFind" @blur="asyncFind" :disabled="isPostalCodeDisabled" maxlength="10" />


              <input class="form-control mt-2" type="text" id="states" list="state-list" v-model="state"
                placeholder="City/State">
              <datalist id="state-list">
                <option v-for="(item, index) in places" :key="index" :value="item['place name'] + ', ' + item.state">
                  {{ item['place name'] }}, {{ item.state }}
                </option>
              </datalist>


              <div class="row">
                <div class="col-md-3">
                  <select class="form-control mr-1 mt-2" v-model="phoneCode" name="phoneCode" id="phoneCode">
                    <option value="1" selected>+1 United States</option>
                    <option v-for="(item, index) in countries" :key="index" :value="item.phone_code">
                      +{{item.phone_code}} {{item.name}}
                    </option>
                  </select>
                </div>
                <div class="col-md-9">
                  <input type="text" class="form-control mr-1 mt-2" placeholder="Phone number" v-model="phone"
                    maxlength="10" />
                </div>
              </div>


            </div>
            <!-- <button class="btn btn-primary btn-sm mt-3" @click="saveCard"><span class="spanButton">Save
                Card</span></button> -->
            <vs-button @click="saveCard()">Save Card</vs-button>
            <table class="table mt-3">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Payment Method</th>
                  <th>Status</th>
                  <th>Operations</th>
                </tr>
              </thead>

              <tbody>
                <tr v-for="(item, index) in paymentMethods" :key="index">
                  <td>
                    <span
                      v-if="item.methodData && item.methodData.billing_details">{{item.methodData.billing_details.name}}</span>
                  </td>
                  <td>
                    <template v-if="item.methodData.card.brand=='visa'">
                      <img src="@/assets/visa.png" width="33">
                    </template>
                    <template v-if="item.methodData.card.brand=='mastercard'">
                      <img src="@/assets/master-card.png" width="33">
                    </template>
                    <template v-if="item.methodData.card.brand=='amex'">
                      <img src="@/assets/amex.png" width="33">
                    </template>
                    <template v-if="item.methodData.card.brand=='discover'">
                      <img src="@/assets/discover.png" width="33">
                    </template>
                    <template v-if="item.methodData.card.brand=='jbc'">
                      <img src="@/assets/jbc.png" width="33">
                    </template>
                    •••• {{item.methodData.card.last4}}
                  </td>
                  <td>
                    <template v-if="parseInt(item.isDefault)==1">
                      <span class="material-icons smallText me-1">star</span>Default card
                    </template>
                    <template v-else>
                      <a class="text-link" @click="changeDefaultCard(item)">Set as default</a>
                    </template>
                  </td>
                  <td>
                    <template v-if="parseInt(paymentMethods.length)==1">
                      <a class="text-link smallText" @click="deleteMethodModal(item)" v-if="item.level!='admin'"><span
                          class="material-icons smallText me-1">delete</span>Delete</a>
                    </template>
                    <template v-else>
                      <template v-if="parseInt(item.isDefault)==1">
                        Set a new default payment method
                      </template>
                      <template v-else>
                        <a class="text-link smallText" @click="deleteMethodModal(item)" v-if="item.level!='admin'"><span
                            class="material-icons smallText me-1">delete</span>Delete</a>
                      </template>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

        </div>

      </div>

    </div>
  </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  import { loadStripe } from '@stripe/stripe-js';
  import { CardElement } from '@stripe/stripe-js';
  import axios from 'axios';
  var config = require("@/config/api.json");
  var countryList = require("@/config/countries.json");
  const API = config.api;
  const zipApi = config.zipApi;


  import {
    createUserWithEmailAndPassword,
    sendEmailVerification,
    EmailAuthProvider,
    updateEmail,
    reauthenticateWithCredential,
    updatePassword,
    updateProfile
  } from "firebase/auth";
  import { auth } from "@/fb";

  export default {
    name: "Settings",
    computed: {
      isPostalCodeDisabled() {
        return this.location === 'xx';
      }
    },

    data() {
      return {
        displayBilling: false,
        isVerified: false,
        userEmail: null,
        currentPassword: null,
        newPs: "",
        confirmPs: "",
        newDisplayName: "",
        stripe: null,
        cardElement: null,
        line1: null,
        line2: null,
        postalCode: null,
        owner: null,
        billingEmail: null,
        location: "xx",
        state: null,
        countries: [],
        places: [],
        phoneCode: "1",
        phone: null,
        tin: null,
        paymentMethods: [],
        modalDelete: false,
        selectedMethod: []
      };
    },
    mounted() {
      const user = auth.currentUser;
      if (user) {
        this.isVerified = user.emailVerified;
      }

      this.initializeStripe();
      this.getCountries();
      this.getPaymentMethods();

    },
    methods: {
      ...mapActions(['updateDisplayName']),

      enviarMensajeElectron() {
        console.log(this.user)
      // Enviar mensaje a Electron a través de WebSockets
      const message = JSON.stringify({
        action: "refresh",
        text: "¡Hola desde la WebApp!"
      });
      console.log(':::socket', this.$socket)
      this.$socket.send(message);
    },

      async deleteMethod() {

        const paymentInfo = {
          stripeId: this.selectedMethod.stripeId,
          uid: this.selectedMethod.parent_uid
        };
        this.openLoading();

        try {
          await axios.post(`${API}/deletePaymentMethod`, paymentInfo);
          await this.updateUserInfo(this.selectedMethod.parent_uid);
          await this.getPaymentMethods();
        } catch (error) {
          // Manejar el error, mostrar una notificación de error, etc.
          this.notify('error', error.response.data.error);
        } finally {
          this.selectedMethod = [];
          this.modalDelete = false;
          this.closeLoading();
        }

      },
      deleteMethodModal(item) {
        this.selectedMethod = item;
        this.modalDelete = true;
      },
      async changeDefaultCard(val) {
        if (val.isDefault && parseInt(val.isDefault) == 1) {
          return;
        }

        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          var userObject = JSON.parse(storedUser);
        }

        if (parseInt(val.isDefault) == 0) {
          const paymentInfo = {
            stripeId: val.stripeId,
            uid: val.parent_uid
          };
          this.openLoading();

          try {
            await axios.post(`${API}/changeDefaultCard`, paymentInfo);
            await this.updateUserInfo(userObject.uid);
            await this.getPaymentMethods();
          } catch (error) {
            // Manejar el error, mostrar una notificación de error, etc.
            this.notify('error', error.response.data.error);
          } finally {
            this.closeLoading();
          }
        }
      },
      clearFields() {
        this.postalCode = null;
        this.state = null;
        this.places = [];
      },
      getCountries() {
        this.countries = countryList;

      },
      async getPaymentMethods() {
        this.paymentMethods = [];
        const storedUser = localStorage.getItem('user');
        if (storedUser) {
          const userObject = JSON.parse(storedUser);
          if (userObject.paymentMethods) {
            this.paymentMethods = userObject.paymentMethods;
            try {
              for (var paymentMethod of this.paymentMethods) {
                const response = await axios.get(`${API}/getPaymentMethod/${paymentMethod.stripeId}`);
                paymentMethod.parent_uid = userObject.parent_uid;
                // Agregar los datos de pago directamente al objeto paymentMethod usando $set
                this.$set(paymentMethod, 'methodData', response.data);
              }
            } catch (error) {
              console.error('Error al obtener los datos de los PaymentMethods:', error);
            }
          }
        }
      },

      async asyncFind() {
        if (this.postalCode.length >= 5) {
          await axios.get(`${zipApi}/${this.location}/${this.postalCode}`, {
          })
            .then((response) => {
              console.log(response.data)
              this.places = response.data.places
            })
            .catch(function (error) {
              console.log(error)
            })
        }
      },
      async initializeStripe() {
        this.stripe = await loadStripe('pk_test_4YPnh1YgvCH9ltCRyOYK8Irm');
        const elements = this.stripe.elements();

        this.cardElement = elements.create('card', {
          style: {
            base: {
              fontSize: '16px',
              color: "#ffffff"
            },
          }
        });
        this.cardElement.mount(this.$refs.cardElement);
      },
      async saveCard() {


        if (!this.vEmpty(this.owner, 'Billing name')) {
          return;
        }
        if (!this.vEmpty(this.tin, 'TIN/VAT number')) {
          this.tin = "-"
        }

        if (!this.vEmpty(this.billingEmail, 'Billing email')) {
          return;
        }

        if (!this.vEmail(this.billingEmail)) {
          this.notify('error', 'Invalid billing email format');
          return;
        }

        if (!this.vEmpty(this.line1, 'Address line 1')) {
          return;
        }
        if (!this.line2) {
          this.line2 = "-"
        }

        if (this.location == "xx") {
          this.notify('error', 'Please select a country');
          return;
        }

        if (!this.vEmpty(this.postalCode, 'Postal code')) {
          return;
        }

        if (!this.vEmpty(this.state, 'State')) {
          return;
        }

        if (!this.vEmpty(this.phoneCode, 'Phone code')) {
          return;
        }

        if (!this.vEmpty(this.phone, 'Phone')) {
          return;
        }

        if (this.phone.length != 10) {
          this.notify('error', 'Invalid phone number');
          return;
        }



        const { error, paymentMethod } = await this.stripe.createPaymentMethod({
          type: 'card',
          card: this.cardElement,
          billing_details: {
            address: {
              line1: this.line1,
              line2: this.line2,
              state: this.state,
              postal_code: this.postalCode,
              country: this.location
            },
            name: this.owner,
            email: this.billingEmail,
            phone: `+${this.phoneCode}${this.phone}`
          }
        });

        if (error) {
          console.log(error)
          this.notify("error", error.message);
          return;
        } else {

          const storedUser = localStorage.getItem('user');
          if (storedUser) {
            const userObject = JSON.parse(storedUser);


            const paymentInfo = {
              paymentMethod: paymentMethod,
              uid: userObject.parent_uid,
              email: this.billingEmail,
              displayName: this.owner,
              tin: this.tin
            };
            this.openLoading();

            try {
              await axios.post(`${API}/createPaymentMethod`, paymentInfo)
              await this.updateUserInfo(userObject.uid);
              await this.getPaymentMethods();
            } catch (error) {
              // Manejar el error, mostrar una notificación de error, etc.
              console.log(error)
              this.notify('error', error.response.data.error);
              this.closeLoading();
              return;
            } finally {
              this.owner = null;
              this.tin = null;
              this.line1 = null;
              this.line2 = null;
              this.billingEmail = null;
              this.phone = null;
              this.postalCode = null;
              this.location = null;
              this.cardElement.clear();


              this.closeLoading();
              this.notify('success', 'Payment method stored successfully.');
            }
          }
        }
      },
      changePass() {
        if (!this.vEmpty(this.newPs, "New Password") || !this.vEmpty(this.confirmPs, "Confirm New Password")) {
          return;
        }

        if (this.newPs !== this.confirmPs) {
          this.notify("error", "Passwords do not match.");
          return;
        }

        const user = auth.currentUser;
        if (!user) {
          this.notify("error", "User not authenticated.");
          return;
        }

        this.openLoading();

        const credential = EmailAuthProvider.credential(user.email, this.currentPassword);

        reauthenticateWithCredential(user, credential)
          .then(() => {
            updatePassword(user, this.newPs)
              .then(() => {
                this.notify("success", "Password updated successfully.");
                this.currentPassword = null;
                this.newPs = null;
                this.confirmPs = null;
              })
              .catch((error) => {
                console.error("Error updating password:", error);
                this.notify("error", `Error updating password: ${error.message}`);
              })
              .finally(() => {
                this.closeLoading();
              });
          })
          .catch((error) => {
            this.closeLoading();
            console.error("Error reauthenticating user:", error);
            this.notify("error", "Invalid current password.");
          });
      },

      changeEmail() {
        // Validar si el nuevo correo electrónico es válido
        if (!this.vEmail(this.userEmail)) {
          this.notify("error", "Invalid email format.");
          return;
        }
        const user = auth.currentUser;
        if (!user) {
          this.notify("error", "User not authenticated.");
          return;
        }

        this.openLoading();
        const credential = EmailAuthProvider.credential(user.email, this.currentPassword);

        reauthenticateWithCredential(user, credential)
          .then(() => {
            updateEmail(user, this.userEmail)
              .then(() => {
                this.notify("success", "Email updated successfully.");
                this.userEmail = null;
                this.currentPassword = null;
              })
              .catch((error) => {
                console.error("Error updating email:", error);
                this.notify("error", `Error updating email: ${error.message}`);
              })
              .finally(() => {
                this.closeLoading();
              });
          })
          .catch((error) => {
            this.closeLoading();
            console.error("Error reauthenticating user:", error);
            this.notify("error", "Invalid current password.");
          });
      },

      changeDisplayName() {
        if (!this.vEmpty(this.newDisplayName, "New Display Name")) {
          return;
        }

        const user = auth.currentUser;
        if (!user) {
          this.notify("error", "User not authenticated.");
          return;
        }

        this.openLoading();

        updateProfile(user, { displayName: this.newDisplayName })
          .then(() => {
            this.notify("success", "Display name updated successfully.");
            const newDisplayName = this.newDisplayName;
            this.updateDisplayName(newDisplayName);
            this.$emit('displayNameChanged', newDisplayName);
          })
          .catch((error) => {
            console.error("Error updating display name:", error);
            this.notify("error", `Error updating display name: ${error.message}`);
          })
          .finally(() => {
            this.newDisplayName = null;
            this.closeLoading();
          });
      },

      sendVerify() {
        this.openLoading();
        sendEmailVerification(auth.currentUser)
          .then(() => {
            console.log("Verification email sent.");
            this.closeLoading();
            this.notify("success", "Verification email sent successfully.");
          })
          .catch((error) => {
            console.error("Error sending verification email:", error);
            this.closeLoading();
            this.notify("error", `Error sending verification email: ${error.code}`);
          });
      },
    },
  };
</script>